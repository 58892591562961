import styled, { css } from "styled-components";
import { device } from "../mediaQueries";
import { variables } from "../variables";

export default styled.div`
  max-width: ${(props) => (props.small ? "750px" : "1200px")};
  margin: ${(props) => (props.marginless ? "0 auto" : "3rem auto")};
  padding: 1rem;

  ${(props) =>
    props.privacy &&
    css`
      font-size: 16px;
      * {
        margin-bottom: 1rem !important;
      }
      h1 {
        text-align: center;
        font-size: 30px;
        margin-bottom: 2rem !important;
      }
    `}

  ${(props) =>
    props.copy &&
    css`
      margin-bottom: 8rem;
      font-size: 18px;
      color: ${variables.colorGreyDark};

      * {
        margin-bottom: 1.8rem;
      }

      a {
        color: ${variables.colorPrimary};
      }

      a:hover {
        color: ${variables.colorPrimaryDark};
      }

      ${(props) =>
        !props.noCaps &&
        css`
          p:first-of-type {
            &::first-letter {
              float: left;
              font-family: Georgia;
              font-size: 65px;
              line-height: 50px;
              padding-top: 4px;
              padding-right: 8px;
              padding-left: 3px;
              text-transform: capitalize;
            }
          }
        `}

      p {
        font-weight: 400;
      }

      blockquote,
      li {
        margin-bottom: 0.18rem;
        margin-left: 1.5rem;
        p {
          all: unset;
        }
      }

      img {
        max-width: 90vw;
        width: 100%;
        /* width: 800px; */
      }
    `}
`;
