import React, { Component } from "react";
import { graphql } from "gatsby";
import Helmet from "react-helmet";
import get from "lodash/get";
import Img from "gatsby-image";
import { Link } from "gatsby";
import Layout from "../components/layout/layout";
import Fade from "../components/svgGif/blogFade";
import {
  BLOCKS,
  // MARKS,
  INLINES,
} from "@contentful/rich-text-types";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { createGlobalStyle } from "styled-components";

import SlideInModal from "../components/svgGif/slideInModal";
import StyledWrapper from "../styled-components/shared/StyledWrapper";
import StyledArticleHeader from "../styled-components/Header/StyledArticleHeader";

import { renderRichText } from "gatsby-source-contentful/rich-text";

const GlobalStyles = createGlobalStyle`
body {
  background: #fff;
}
`;

class BlogPostTemplate extends Component {
  state = {
    open: false,
  };

  showAuthorInfo = () => {
    this.setState({
      open: !this.state.open,
    });
  };
  render() {
    const post = get(this.props, "data.contentfulBlogPost");
    // const document = get(this.props, "data.contentfulBlogPost.childContentfulBlogPostBodyRichTextNode.json");
    // const allBlogText = post.childContentfulBlogPostBodyRichTextNode.json.content.map((item) => item.content[0].value).toString();
    // const richText = post.richText;
    // console.log(richText);
    // console.log(allBlogText, 'all blog text')

    const countWords = (str) => {
      str = str.replace(/(^\s*)|(\s*$)/gi, "");
      str = str.replace(/[ ]{2,}/gi, " ");
      str = str.replace(/\n /, "\n");
      return str.split(" ").length;
    };

    // const readingLength = Math.round(countWords(longText) / 170); // 170 is the WPM reading speed

    const title = post.title;
    const description = post.description.childMarkdownRemark.internal.content;
    const image = post.heroImage.fluid.src;
    const windowGlobal = typeof window !== "undefined" && window;
    const longText = post.longText.childMarkdownRemark.html;

    return (
      <>
        <GlobalStyles />
        <Helmet title={`${title} | My ADU Story`}>
          <meta property='og:title' content={title} />
          <meta property='og:image' content={image} />
          <meta property='og:description' content={description} />
        </Helmet>
        <Layout>
          <div className='wrapper'>
            <StyledArticleHeader>
              <div className='author-section'>
                <h1 className='section-headline'>{post.title}</h1>
                <div className='author-header'>
                  <Img className='author-image' alt={post.author.name} fluid={post.author.thumbnailImage.fluid} />
                  <span className='author-name'>
                    by <span>{post.author.name}</span> on {post.publishDate}
                  </span>
                  <i onClick={this.showAuthorInfo} className='author-info-icon'>
                    {this.state.open ? <span>&times;</span> : <span>i</span>}
                  </i>
                </div>
                <Fade in={this.state.open} innerHtml={post.author.shortBio.childMarkdownRemark.html} className='author-description' />
                <SlideInModal type='contact' />
              </div>

              <div className='blog-hero'>
                <Img alt={post.title} fluid={post.heroImage.fluid} />
              </div>
            </StyledArticleHeader>
          </div>

          <StyledWrapper copy small>
            {longText && <div dangerouslySetInnerHTML={{ __html: longText }} />}
            {/* {documentToReactComponents(document)} */}
          </StyledWrapper>
        </Layout>
      </>
    );
  }
}

export default BlogPostTemplate;

//

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    contentfulBlogPost(slug: { eq: $slug }) {
      id
      title
      longText {
        childMarkdownRemark {
          html
        }
      }
      description {
        childMarkdownRemark {
          internal {
            content
          }
        }
      }
      author {
        slug
        name
        title
        image {
          fluid(maxWidth: 1200) {
            src
          }
        }
        shortBio {
          childMarkdownRemark {
            html
          }
        }
        thumbnailImage {
          fluid(maxWidth: 1200) {
            src
          }
        }
      }
      slug
      publishDate(formatString: "MMMM Do, YYYY")
      heroImage {
        fluid(maxWidth: 1920, background: "rgb:000000") {
          src
        }
      }
    }
  }
`;
