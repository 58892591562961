import styled from "styled-components";
import { device } from "../mediaQueries";
import { variables } from "../variables";

export default styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 2rem 0 3rem 0;
  min-height: 450px;

  .readingLength {
    padding: 0.5rem;
    margin-bottom: 1rem;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 900;
  }

  .section-headline {
    padding: 0.5rem;
    text-align: left;
    font-size: 36px;
  }

  .author-section {
    @media (max-width: 700px) {
      margin-top: 6rem;
    }

    padding: 0.25rem;
    min-width: 35%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .author-header {
    display: flex;
    flex-direction: row;
    padding-bottom: 0.5rem;
  }

  .author-name {
    span {
      color: black;
    }
    color: ${variables.colorGreyTransparent};
    margin: 1rem 0 0 0.5rem;
  }

  .author-image {
    width: 50px;
    height: 50px;
    border-radius: 500px;
    margin: 0.5rem;
  }

  .author-info-icon {
    cursor: pointer;
    margin: 1rem 1rem 0;
    font-style: normal;
    height: 24px;
    width: 24px;
    min-width: 24px;
    border-radius: 50%;
    border: 2px solid ${variables.colorGreyDark2};
    display: flex;
    justify-content: center;
    align-content: center;
    span {
      user-select: none;
      text-align: center;
      margin-top: -6.2px;
      margin-right: 0.5px;
      font-size: 20px;
    }
  }

  .author-description {
    font-size: 13px;
    color: ${variables.colorGreyLight3};
  }

  .blog-hero {
    margin-left: 1rem;
    min-width: 65%;
    max-height: 500px;

    .gatsby-image-wrapper {
      height: 100%;
    }
  }

  @media ${device.tabPort} {
    .blog-hero {
      min-width: 50% !important;
      max-height: 350px;
    }

    .author-image {
      width: 42px;
      height: 42px;
      min-width: 42px;
      margin-top: 14px;
    }

    .author-name {
      font-size: 16px;
    }
  }

  @media (max-width: 800px) {
    /* flex-direction: column !important; */
    display: block;

    .cta-main {
      margin: 1rem;
    }

    .blog-hero {
      margin-right: 1rem;
      .gatsby-image-wrapper {
        /* height: 70vh; */
        height: 300px;
        margin-bottom: 2rem;
      }
    }
  }
`;
