import styled, { css } from "styled-components";
import { variables } from "../variables";

export default styled.div`
  ${(props) =>
    props.background &&
    css`
      background-color: ${variables.colorPrimary};
      border-radius: 20px;
      padding: 3rem;
      margin: 2rem;

      @media (min-width: 600px) {
        /* transform: scale(1.2); */
      }
    `}

  padding: 2rem 2rem 2rem 2rem;
  max-width: 400px;
  position: relative;

  button {
    margin: 8px auto 0;
    width: 200px;
  }

  p {
    color: white;
    margin-bottom: 1rem;
  }

  form {
    display: flex;
    flex-direction: column;

    input,
    textarea {
      border: 1px solid ${variables.colorGreyDark};
      outline: 0 !important;
      /* padding: 2px 6px; */
      padding: 0.5rem 1rem;
      border-radius: 10px;
      margin-bottom: 1.5rem;
    }

    input:focus,
    textarea:focus {
      border: 1px solid ${variables.colorGreyDark2};
    }
  }
`;
