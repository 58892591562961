import React, { useState, Fragment } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";
import StyledContactForm from "../../styled-components/Body/StyledContactForm";
import StyledCloseBtn from "../../styled-components/shared/StyledCloseBtn";
import TextField from "@mui/material/TextField";

const ContactForm = ({ background, closeModal }) => {
  const [formData, setFormData] = useState({
    email: "",
    message: "",
  });

  const [placeholders, setPlaceholders] = useState({
    placeholderEmail: "Email",
    placeholderMessage: "Message",
  });

  const { placeholderEmail, placeholderMessage } = placeholders;
  const { email, message } = formData;
  const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

  const recaptchaRef = React.useRef();

  const onSubmitWithReCAPTCHA = async (e) => {
    e.preventDefault();

    // const token = await recaptchaRef.current.executeAsync();
    const token = await recaptchaRef.current.executeAsync();

    if (token) {
      sendEmail(token);
      setFormData({ email: "", message: "" });
      setTimeout(() => {
        setPlaceholders({
          placeholderEmail: "Your message has been sent!",
          placeholderMessage: "We will get back to you as soon as possible!",
        });
      }, 1);
      setTimeout(() => {
        setPlaceholders({
          placeholderEmail: "Email",
          placeholderMessage: "Message",
        });
      }, 8000);
    }
  };

  let windowLocation = typeof window !== "undefined" && window.location.href;

  const sendEmail = async (token) => {
    const fullData = { email: `${formData.email} at <br></br> ${windowLocation && windowLocation} `, message: formData.message };
    const data = { ...fullData, token: token };

    const url = "https://us-central1-hbh-firebase.cloudfunctions.net/api/contactUs";
    // const url = 'http://localhost:5000/hbh-firebase/us-central1/api/contactUs'

    await axios.put(url, data);
    // console.log(response, 'this is the response')
  };

  return (
    <StyledContactForm background={background}>
      <span class='close black'></span>
      {closeModal && <StyledCloseBtn onClick={closeModal && closeModal}>&times;</StyledCloseBtn>}
      <p>Questions? Ask them here</p>
      <form onSubmit={onSubmitWithReCAPTCHA}>
        <label htmlFor='email' style={{ color: "white" }}>
          Email
        </label>
        <TextField />
        {/* <Input placeholder="johndoe@gmail.com" onChange={onChange} value={email} valueName="email" type="email" required max="30" /> */}

        {/* <label htmlFor="contactMessage" style={{ color: "white" }}>
          {placeholderMessage}
        </label> */}
        <label htmlFor='message' style={{ color: "white" }}>
          Questions / Comments:
        </label>

        <textarea
          placeholder='Type message...'
          style={{ marginTop: ".5rem", fontSize: "1.2rem" }}
          onChange={onChange}
          value={message}
          name='message'
          type='text'
          required
          max='1000'
        />
        <ReCAPTCHA style={{ display: "none" }} sitekey='6LfYorAZAAAAAMEM5RVTMpzwp4pwmv4UDWm8okG4' ref={recaptchaRef} size='invisible' />
        <button className='cta cta-main' style={{ padding: "1rem 2rem" }} type='submit'>
          Send
        </button>
      </form>
    </StyledContactForm>
  );
};

export default ContactForm;
