import styled, { css } from 'styled-components'
import { variables } from '../variables'

export default styled.div`

      position: absolute;
      right: 0;
      width: 28px;
      margin-top: -30px;
      margin-right: 8px;
      cursor: pointer;
      text-align: center;
      font-size: 26px;
      font-weight: 900;
      color: ${variables.colorWhite};
      transition: all .5s;
      &:hover {
        color: ${variables.colorGreyTransparent};
      }

`