import React from "react";
import { Transition } from "react-transition-group";

const duration = 600;

const defaultStyle = {
  transition: `opacity ${duration}ms ease-in-out, max-height .6s ease-in-out, margin .6s ease-in-out`,
  opacity: 0,
  maxHeight: "0px",
  overflow: "hidden",
  //   position: 'absolute'
};

const transitionStyles = {
  entering: { opacity: 0, maxHeight: "0px" },
  entered: { opacity: 1, maxHeight: "200px" },
  exiting: { opacity: 0, maxHeight: "200px" },
  exited: { opacity: 0, maxHeight: "0px" },
};

const Fade = ({ in: inProp, innerHtml, className }) => (
  <Transition in={inProp} timeout={duration}>
    {(state) => (
      <div
        className={className}
        dangerouslySetInnerHTML={{
          __html: innerHtml,
        }}
        style={{
          ...defaultStyle,
          ...transitionStyles[state],
        }}
      >
        {/* {children} */}
      </div>
    )}
  </Transition>
);

export default Fade;
