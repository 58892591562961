import React, { useState } from "react";
import { Fragment } from "react";
import Modal from "react-modal";
import Fade from "react-reveal/Fade";
import SecondaryContactForm from "../form-items/secondary-contact-form";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "transparent",
    border: "none",
    width: "100vw",
    display: "flex",
    justifyContent: "center",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.35)",
  },
};

// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement("#___gatsby");

const SlideInModal = () => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <Fragment>
      <div style={{ display: "flex", justifyContent: "center" }}>
        {/* <button className='cta cta-main' style={{ marginTop: "1.5rem", padding: ".75rem 2rem", fontSize: "18px" }} onClick={openModal}>
          Contact RJ
        </button> */}
        <Modal isOpen={modalIsOpen} onRequestClose={closeModal} style={customStyles} contentLabel='Example Modal'>
          <Fade left>
            <SecondaryContactForm background closeModal={closeModal} />
          </Fade>
        </Modal>
      </div>
    </Fragment>
  );
};

export default SlideInModal;
